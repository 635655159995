/* eslint-disable jsx-a11y/img-redundant-alt */
import { useState, useEffect } from 'react';

import BlockTableImageNoCrop from './Block-table-image-no-crop.webp';
import BlockTableImage from './Block-table-image.webp';
import XBlockLeft from './x-block-left.webp';
import XBlockRight from './x-block-right.webp';

export const FeaturesSection = () => {
  const [imageSrc, setImageSrc] = useState(BlockTableImage);

  useEffect(() => {
    const debounce = (func: () => void, delay: number) => {
      let timer: ReturnType<typeof setTimeout>;
      return () => {
        clearTimeout(timer);
        timer = setTimeout(func, delay);
      };
    };

    const handleResize = debounce(() => {
      if (window.innerWidth >= 1520) {
        setImageSrc(BlockTableImageNoCrop);
      } else {
        setImageSrc(BlockTableImage);
      }
    }, 100);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section id="features" className="relative bg-white">
      <div className="w-full xl:mx-auto xl:max-w-[1520px] xl:px-0">
        <div className="ml-2 pt-24 xs:ml-4 sm:ml-6 md:pt-40 lg:pl-24 lg:pt-40 xl:ml-0 xl:grid xl:grid-cols-2 xl:gap-24 xl:px-0 xl:pt-48">
          <div className="px-4 max-2xl:pl-0 md:px-24 lg:px-12 xl:pl-24 2xl:pl-0">
            <p className="max-w-full text-left font-inter text-lg font-light leading-[140%] text-slate-700 md:text-22 md:font-normal xl:pr-0">
              Breaking into the paid streaming world is tough, especially for
              micro and mid-size creators.
            </p>
            <h2 className="max-w-full bg-gradient-to-b from-purple-500 to-pink-500 bg-clip-text pt-6 font-inter text-2xl font-semibold leading-tight text-transparent md:text-32 md:font-semibold xl:max-w-[532px] xl:text-40">
              Our platform bridges that gap by matching streamers and brands in
              minutes. Find campaigns that fit your style - no more waiting to
              be discovered. Start monetizing and growing your streams
              instantly.
            </h2>
          </div>
          <div className="ml-4 mt-10 flex sm:ml-24 xl:mx-0 xl:mt-0 xl:flex xl:items-start">
            <img
              src={imageSrc}
              alt="Feature image illustrating the platform's matching process"
              className="z-20 ml-auto h-[272px] w-[418px] md:h-[507px] md:w-[780px] lg:size-auto 2xl:pr-4"
            />
          </div>
        </div>
      </div>

      <img
        src={XBlockLeft}
        alt="Decorative block element on the left"
        className="
          absolute left-0 
          top-[20px] h-[50px] 
          sm:top-[20px] sm:h-[87px]
          lg:top-[222px] lg:h-[87px]
        "
      />
      <img
        src={XBlockRight}
        alt="Decorative block element on the right"
        className="
          absolute bottom-[-52px] right-0
          z-10 h-[82px]
          sm:bottom-[-120px] sm:h-[152px] lg:h-[152px]
        "
      />
    </section>
  );
};
