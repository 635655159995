import { XIcon, AlignJustifyIcon } from 'lucide-react';
import { useState } from 'react';

import { Link } from '@remix-run/react';
import { Logo } from '@web/components/logo/logo';
import { Button } from '@web/components/ui/button';
import {
  overviewRoute,
  featuresRoute,
  benefitsRoute,
  companyRoute,
  streamerSignInRoute,
  appRoute
} from '@web/utilities/routes';
import { cn } from '@web/utilities/styles';

export const Navigation = ({
  isAuthenticated
}: {
  isAuthenticated: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav
      className={cn(
        'fixed inset-x-0 top-0 z-50 flex h-10 items-center justify-between bg-white/50 px-4 text-black backdrop-blur-[75px] lg:backdrop-blur-0 xl:h-auto xl:px-24 xl:py-4 xl:bg-transparent'
      )}
    >
      <div className="mx-auto flex w-full max-w-[1520px] items-center justify-between">
        <Link to="/" title="Go to homepage">
          <Logo className="h-4 w-[68px] sm:h-6 sm:w-24 xl:h-[31px] xl:w-[131px]" />
        </Link>

        <button
          onClick={() => setIsOpen(!isOpen)}
          aria-label={isOpen ? 'Close menu' : 'Open menu'}
          className="xl:hidden"
        >
          {isOpen ? (
            <XIcon className="size-6" />
          ) : (
            <AlignJustifyIcon className="size-6" />
          )}
        </button>

        <MobileMenu isOpen={isOpen} isAuthenticated={isAuthenticated} />
        <DesktopMenu isAuthenticated={isAuthenticated} />
      </div>
    </nav>
  );
};

const JoinButton = ({
  className = '',
  isMobile = false,
  isAuthenticated
}: {
  className?: string;
  isMobile?: boolean;
  isAuthenticated: boolean;
}) => (
  <Button
    variant="landing"
    size="default"
    className={cn(
      isMobile ? 'h-[49px] w-[171px]' : 'h-[33px] w-[111px]',
      'gap-2 text-sm font-semibold rounded-md',
      className
    )}
    asChild
  >
    <Link
      to={isAuthenticated ? appRoute() : streamerSignInRoute()}
      prefetch="intent"
    >
      {isAuthenticated ? 'Go to app' : 'Join Now'}
    </Link>
  </Button>
);

const MobileMenu = ({
  isOpen,
  isAuthenticated
}: {
  isOpen: boolean;
  isAuthenticated: boolean;
}) => (
  <div
    className={cn(
      'transition-max-height absolute left-0 top-full w-full overflow-hidden bg-white duration-500 ease-in-out',
      isOpen ? 'max-h-screen border-b border-slate-400' : 'max-h-0',
      'xl:hidden'
    )}
  >
    <ul className="flex flex-col items-center">
      <li className="mt-6 text-center">
        <Link
          to={overviewRoute()}
          prefetch="intent"
          className="mb-6 block font-inter text-base font-light text-slate-900"
        >
          Overview
        </Link>
      </li>
      <li className="text-center">
        <Link
          to={featuresRoute()}
          prefetch="intent"
          className="mb-6 block font-inter text-base font-light text-slate-900"
        >
          Features
        </Link>
      </li>
      <li className="text-center">
        <Link
          to={benefitsRoute()}
          prefetch="intent"
          className="mb-6 block font-inter text-base font-light text-slate-900"
        >
          Benefits
        </Link>
      </li>
      <li className="text-center">
        <Link
          to={companyRoute()}
          prefetch="intent"
          className="mb-6 block font-inter text-base font-light text-slate-900"
        >
          Company
        </Link>
      </li>
      <li className="mb-10 mt-4">
        <JoinButton isMobile isAuthenticated={isAuthenticated} />
      </li>
    </ul>
  </div>
);

const DesktopMenu = ({ isAuthenticated }: { isAuthenticated: boolean }) => (
  <div
    className={cn(
      'hidden items-center gap-10 rounded-xl px-4 py-2 xl:flex bg-white'
    )}
  >
    <div className="flex items-center gap-10">
      <Link
        to={overviewRoute()}
        prefetch="intent"
        className="text-center font-inter text-sm font-normal leading-normal text-slate-900"
      >
        Overview
      </Link>
      <Link
        to={featuresRoute()}
        prefetch="intent"
        className="text-center font-inter text-sm font-normal leading-normal text-slate-900"
      >
        Features
      </Link>
      <Link
        to={benefitsRoute()}
        prefetch="intent"
        className="text-center font-inter text-sm font-normal leading-normal text-slate-900"
      >
        Benefits
      </Link>
      <Link
        to={companyRoute()}
        prefetch="intent"
        className="text-center font-inter text-sm font-normal leading-normal text-slate-900"
      >
        Company
      </Link>
    </div>
    <JoinButton isAuthenticated={isAuthenticated} />
  </div>
);
