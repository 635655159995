import { useEffect, useState } from 'react';

import { JoinButtons } from '../JoinButtons/JoinButtons';

import microphoneLeftSafari from './microphone-left-safari.mp4';
import microphoneLeftWebm from './microphone-left.webm';
import microphoneRightSafari from './microphone-right-safari.mp4';
import microphoneRightWebm from './microphone-right.webm';

export const CallToActionSection = ({
  isAuthenticated
}: {
  isAuthenticated: boolean;
}) => {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsSafari(/safari/.test(userAgent) && !/chrome/.test(userAgent));
  }, []);

  return (
    <section className="relative flex flex-col items-center bg-white pb-24 sm:pb-[152px] xl:pb-48">
      <h2 className="mt-24 max-w-[312px] text-center font-inter text-32 font-semibold uppercase leading-tight text-slate-900 sm:mt-[152px] sm:text-40 md:max-w-[352px] xl:mt-[192px] xl:max-w-[616px]">
        Ready to turn <br className="hidden xl:block" />
        your passion into profit?
      </h2>
      <div className="mt-6 h-[5px] w-28 bg-slate-900 sm:mt-12"></div>
      <p className="mt-12 max-w-[358px] text-center font-inter text-2xl font-normal leading-tight text-slate-700 sm:max-w-[646px] sm:text-32 xl:mt-14 xl:max-w-none">
        Join Onezee today and start monetising your livestreams!
      </p>
      <JoinButtons
        className="mt-6 px-4 sm:mt-24 sm:px-0 xl:mt-20"
        variant="discord"
        isAuthenticated={isAuthenticated}
      />
      <video
        autoPlay
        loop
        muted
        playsInline
        src={isSafari ? microphoneRightSafari : microphoneRightWebm}
        className="absolute left-0 top-32 h-[105px] w-[29px] sm:h-[182px] sm:w-[50px] xl:h-[352px] xl:w-24"
      />
      <video
        autoPlay
        loop
        muted
        playsInline
        src={isSafari ? microphoneLeftSafari : microphoneLeftWebm}
        className="absolute right-0 top-32 h-[105px] w-[29px] sm:h-[182px] sm:w-[50px] xl:h-[352px] xl:w-24"
      />
    </section>
  );
};
