import { useEffect, useState } from 'react';

import { JoinButtons } from '../JoinButtons/JoinButtons';

import ballAnimateSafari from './ball-animate-safari.mp4';
import ballAnimateWebm from './ball-animate.webm';
import { BenefitItem } from './BenefitItem';
import gameCutDemo from './game-cut-demo.webm';
import LinesBg from './lines_bg_benefits_block.webp';
import megaphoneAnimateSafari from './megaphone-animate.mp4';
import megaphoneAnimateWebm from './megaphone-animate.webm';
import obsCutDemo from './obs-cut-demo.webm';
import payCutDemo from './pay-cut-demo.webm';

export const BenefitsSection = ({
  isAuthenticated
}: {
  isAuthenticated: boolean;
}) => {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsSafari(/safari/.test(userAgent) && !/chrome/.test(userAgent));
  }, []);

  return (
    <section
      id="benefits"
      className="relative bg-white pt-24 sm:pt-[152px] xl:pt-[192px]"
    >
      <div
        className="absolute inset-y-0 left-1/2 mt-8 size-full -translate-x-1/2 sm:mt-24 xl:mt-12 "
        style={{
          backgroundImage: `url(${LinesBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
          backgroundSize: 'calc(100% - 148px)',
          zIndex: 0
        }}
      />
      <div
        className="absolute inset-y-0 left-1/2 mb-16 size-full -translate-x-1/2 sm:mt-24 xl:mt-10"
        style={{
          backgroundImage: `url(${LinesBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center calc(100% - 120px)',
          backgroundSize: 'calc(100% - 148px)',
          zIndex: 0
        }}
      />
      <div className="relative z-10">
        <video
          src={isSafari ? megaphoneAnimateSafari : megaphoneAnimateWebm}
          autoPlay
          loop
          muted
          playsInline
          className="absolute left-0 top-8 h-[126px] w-[86px] sm:top-[60px] sm:h-[274px] sm:w-[186px] xl:h-[494px] xl:w-[335px]"
        />
        <video
          src={isSafari ? ballAnimateSafari : ballAnimateWebm}
          autoPlay
          loop
          muted
          playsInline
          className="absolute bottom-64 right-0 h-[77px] sm:bottom-48 sm:h-[187px] xl:h-[273px]"
        />
        <h2 className="text-center font-inter text-3xl font-semibold uppercase text-slate-900 sm:text-40">
          benefits
        </h2>
        <div className="mx-auto mt-6 h-[5px] w-28 bg-slate-900 sm:mt-12"></div>
        <div className="mt-14 flex flex-col items-center space-y-14 sm:mt-[76px] sm:space-y-24 xl:mt-24 xl:space-y-[152px]">
          <BenefitItem
            videoSrc={obsCutDemo}
            title="Simple Setup"
            description="Get started in minutes by connecting your Twitch account and installing our OBS plugin. It's that simple!"
          />
          <BenefitItem
            videoSrc={gameCutDemo}
            title="Seamless Ad Integration"
            description="Once you've signed up for campaigns, our platform automatically starts displaying ads during your streams. No manual work is required."
          />
          <BenefitItem
            videoSrc={payCutDemo}
            title="Comprehensive Analytics"
            description="Track the performance of your ad campaigns with our detailed reporting tools. Know exactly how much you're earning and how your audience is engaging."
          />
        </div>
        <JoinButtons
          className="mt-24 px-4 pb-24 sm:mt-24 sm:px-0 sm:pb-[152px] xl:mt-[152px] xl:pb-[192px]"
          variant="discord"
          isAuthenticated={isAuthenticated}
        />
      </div>
    </section>
  );
};
