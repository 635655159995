import { Instagram, Facebook } from 'lucide-react';

import { Link } from '@remix-run/react';
import { Logo } from '@web/components/logo/logo';
import { privacyPolicyRoute, tosRoute } from '@web/utilities/routes';

import DiscordIconWhite from './discord-icon-white.svg';
import TwitterIcon from './x-twitter-icon.svg';

export const Footer = () => {
  return (
    <footer className="bg-slate-100 px-4 pt-10 sm:px-10 sm:pt-10">
      <div className="mx-auto max-w-[1520px]">
        <div className="flex items-center justify-between sm:items-center sm:justify-between">
          <div className="sm:flex sm:flex-col">
            <Logo className="h-[25px] w-[105px] sm:h-[29px] sm:w-[121px]" />
            <p className="hidden sm:mt-6 sm:block sm:text-left sm:font-inter sm:text-xl sm:font-normal sm:text-slate-600">
              Onezee 2024, All rights reserved.
            </p>
          </div>
          <div>
            <div className="mb-4 flex items-center gap-2 sm:gap-4">
              <a
                href="https://discord.gg/eRHuwwzSzz"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Join us on Discord"
                className="flex size-10 items-center justify-center rounded-full bg-violet-500"
              >
                <img
                  src={DiscordIconWhite}
                  alt="Discord Icon"
                  className="size-6"
                />
              </a>
              <a
                href="https://instagram.com/onezee_io"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Follow us on Instagram"
                className="flex size-10 items-center justify-center rounded-full bg-violet-500"
              >
                <Instagram className="size-6 text-white" />
              </a>
              <a
                href="https://facebook.com/onezee.io"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Like us on Facebook"
                className="flex size-10 items-center justify-center rounded-full bg-violet-500"
              >
                <Facebook className="size-6 text-white" />
              </a>
              <a
                href="https://x.com/Onezee_io"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Follow us on Twitter"
                className="flex size-10 items-center justify-center rounded-full bg-violet-500"
              >
                <img src={TwitterIcon} alt="Twitter Icon" className="size-6" />
              </a>
            </div>

            <div>
              <ul className="text-right text-sm">
                <li className="inline-block">
                  <Link
                    to={tosRoute()}
                    className="text-violet-500 hover:underline"
                    title="Terms of service"
                  >
                    Terms of service
                  </Link>
                </li>

                <li className="ml-4 inline-block">
                  <Link
                    to={privacyPolicyRoute()}
                    className="text-violet-500 hover:underline"
                    title="Privacy policy"
                  >
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p className="mt-6 pb-4 text-center font-inter text-xs font-normal text-slate-600 sm:hidden">
          Onezee 2024, All rights reserved.
        </p>
        <div className="hidden sm:block sm:h-10"></div>
      </div>
    </footer>
  );
};
