import { PlusIcon, MinusIcon } from 'lucide-react';
import { useState, useEffect } from 'react';

import { cn } from '@web/utilities/styles';

import Card01 from './Card_01.svg';
import Card02 from './Card_02.svg';
import Dot1 from './dot-1.svg';
import Dot2 from './dot-2.svg';
import Dot3 from './dot-3.svg';
import Dot4 from './dot-4.svg';

type CompanyFeatureProps = {
  title: string;
  description: string;
  imageSrc: string;
  index: number;
};

export const CompanyFeature = ({
  title,
  description,
  imageSrc,
  index
}: CompanyFeatureProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const checkScreenSize = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const getDotSVG = () => {
    switch (index) {
      case 0:
        return Dot1;
      case 1:
        return Dot2;
      case 2:
        return Dot3;
      case 3:
        return Dot4;
      default:
        return Dot1;
    }
  };

  const handleToggle = () => {
    if (!isDesktop || (isDesktop && document.activeElement === document.body)) {
      setIsOpen(!isOpen);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleToggle();
    }
  };

  return (
    <div
      className={cn(
        'relative w-[320px] xs:w-[358px] h-[199px] sm:w-[648px] sm:h-[360px] rounded-2xl overflow-hidden',
        'transition-all duration-300 ease-in-out',
        isDesktop && 'hover:cursor-pointer'
      )}
      onClick={handleToggle}
      onMouseEnter={() => isDesktop && setIsOpen(true)}
      onMouseLeave={() => isDesktop && setIsOpen(false)}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      aria-expanded={isOpen}
      aria-label={isOpen ? 'Collapse feature' : 'Expand feature'}
    >
      <img
        src={isOpen ? Card02 : Card01}
        alt={`Card illustration ${isOpen ? 'expanded' : 'collapsed'}`}
        className="absolute inset-0 size-full object-cover"
      />
      <div className="absolute left-0 top-2 size-10 xs:-left-1 xs:top-0 xs:size-14 sm:left-0 sm:size-24">
        <img
          src={getDotSVG()}
          alt={`Dot decoration ${index + 1}`}
          className="size-full"
          style={{
            filter: isOpen
              ? 'brightness(0) saturate(100%) invert(40%) sepia(74%) saturate(1103%) hue-rotate(230deg) brightness(101%) contrast(101%)'
              : 'none'
          }}
        />
      </div>
      <div
        className={cn(
          'absolute inset-0 transition-opacity duration-300 ease-in-out',
          isOpen ? 'opacity-0' : 'opacity-100'
        )}
      >
        <h3 className="absolute inset-x-4 top-[104px] max-w-[113px] font-inter text-xl font-medium text-slate-900 sm:left-6 sm:top-[189px] sm:max-w-[177px] sm:text-3xl">
          {title}
        </h3>
        <img
          src={imageSrc}
          alt={`Illustration for feature: ${title}`}
          className="absolute right-6 top-[21px] h-[154px] w-[130px] object-contain sm:right-[72px] sm:top-10 sm:h-[280px] sm:w-[237px]"
        />
      </div>

      <div
        className={cn(
          'absolute inset-0 transition-opacity duration-300 ease-in-out',
          isOpen ? 'opacity-100' : 'opacity-0'
        )}
      >
        <p className="absolute inset-x-4 bottom-4 font-inter text-base font-normal leading-[140%] text-slate-700 sm:inset-x-6 sm:bottom-6 sm:text-22 md:text-28">
          {description}
        </p>
      </div>

      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="absolute  right-4 top-0 z-10 size-auto w-4 p-3 text-slate-300 xs:top-2 sm:right-8 sm:top-4 sm:w-6 xl:hidden "
        aria-label={isOpen ? 'Close feature' : 'Open feature'}
      >
        {isOpen ? (
          <MinusIcon
            size={24}
            className="size-5 sm:size-6"
            aria-hidden="true"
          />
        ) : (
          <PlusIcon size={24} className="size-5 sm:size-6" aria-hidden="true" />
        )}
      </button>
    </div>
  );
};
