type BenefitItemProps = {
  videoSrc: string;
  title: string;
  description: string;
};

export const BenefitItem = ({
  videoSrc,
  title,
  description
}: BenefitItemProps) => {
  return (
    <div className="mx-2 flex flex-col items-start xs:w-[358px] sm:mx-0 sm:w-[518px] xl:w-[648px]">
      <video
        src={videoSrc}
        autoPlay
        loop
        muted
        playsInline
        className="z-10 rounded-2xl border-8 border-slate-200 object-cover xs:h-[197px] xs:w-full sm:h-[283px] xl:h-[355px]"
      />
      <h3 className="mt-4 w-full font-inter text-2xl font-semibold text-slate-900 sm:mt-6 sm:text-3xl xl:text-40">
        {title}
      </h3>
      <p className="mt-2 font-inter text-lg font-light leading-[140%] text-slate-700 sm:mt-4 sm:text-22 xl:text-28">
        {description}
      </p>
    </div>
  );
};
