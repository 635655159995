import { MetaFunction, LoaderFunctionArgs } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { authenticator } from '@web/utilities/authenticator';
import { jsonWithFlash } from '@web/utilities/responses';

import { BenefitsSection } from './BenefitsSection/BenefitsSection';
import { CallToActionSection } from './CallToActionSection/CallToActionSection';
import { CompanySection } from './CompanySection/CompanySection';
import { FeaturesSection } from './FeaturesSection/FeaturesSection';
import { Footer } from './Footer/Footer';
import { Navigation } from './Navigation';
import { OverviewSection } from './OverviewSection/OverviewSection';

export const meta: MetaFunction = () => {
  return [
    { title: 'Onezee - Revolutionizing Streamer and Brand Collaborations' },
    {
      name: 'description',
      content:
        'Onezee is a platform that connects streamers with brands for sponsorships your viewers will love. Join our community today!'
    },
    {
      name: 'keywords',
      content:
        'streamer, brand, collaboration, sponsor, sponsorship, affiliate, marketing'
    },
    {
      tagName: 'link',
      rel: 'canonical',
      href: 'https://onezee.io'
    }
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await authenticator.isAuthenticated(request);
  return jsonWithFlash(request, {
    isAuthenticated: !!user
  });
}

export default function Index() {
  const { isAuthenticated } = useLoaderData<{ isAuthenticated: boolean }>();

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className="scroll-smooh">
      <Navigation isAuthenticated={isAuthenticated} />
      <OverviewSection isAuthenticated={isAuthenticated} />
      <FeaturesSection />
      <BenefitsSection isAuthenticated={isAuthenticated} />
      <CompanySection isAuthenticated={isAuthenticated} />
      <CallToActionSection isAuthenticated={isAuthenticated} />
      <Footer />
    </div>
  );
}
