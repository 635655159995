import { Link } from '@remix-run/react';
import { Button } from '@web/components/ui/button';
import { appRoute, streamerSignInRoute } from '@web/utilities/routes';

import bottomRectangle from './bottom-rectangle.svg';
import heroLarge from './hero-large.webp';
import heroMedium from './hero-medium.webp';
import heroSmall from './hero-small.webp';
import streamVideo from './stream-video.webm';

export const OverviewSection = ({
  isAuthenticated
}: {
  isAuthenticated: boolean;
}) => {
  return (
    <section className="relative bg-slate-100">
      <div className="mx-auto mt-24 flex max-w-[1520px] flex-col items-center pb-24 sm:mt-[112px] xl:relative xl:mt-[198px] xl:h-[90vh] xl:flex-row xl:items-start xl:justify-between xl:pb-[199px]">
        <div className="z-20 xl:w-1/2 xl:pl-[96px]">
          <h1 className="text-center font-inter text-32 font-medium uppercase leading-tight text-slate-900 sm:text-56 sm:font-semibold xl:text-left">
            The easiest way
            <br />
            to monetise
            <br />
            your streams
          </h1>
          <p className="mt-4 max-w-[358px] text-center font-inter text-base font-light leading-tight text-slate-700 sm:mt-6 sm:max-w-[530px] sm:text-22 xl:text-left">
            Connecting rising streamers with brands that value unique audiences
          </p>
          <div className="flex justify-center xl:justify-start">
            <Button
              variant="landing"
              size="default"
              className="z-20 mt-6 h-[49px] w-[171px] gap-2 rounded-md text-sm font-semibold sm:mt-10 sm:w-[199px] xl:mt-10 xl:w-[199px] xl:text-left"
              asChild
            >
              <Link
                to={isAuthenticated ? appRoute() : streamerSignInRoute()}
                prefetch="intent"
              >
                {isAuthenticated ? 'Go to app' : 'Join Now'}
              </Link>
            </Button>
          </div>
        </div>
        <div className="relative z-10 mt-[164px] sm:mt-[176px] xl:mt-0 xl:flex xl:w-1/2 xl:justify-start">
          <video
            src={streamVideo}
            autoPlay
            loop
            muted
            playsInline
            className="h-[181px] w-[342px] rounded-2xl border-8 border-slate-200 object-cover sm:h-[272px] sm:w-[514px] xl:h-[341px] xl:w-[648px]"
          />
          <img
            src={heroSmall}
            alt="Hero Small"
            className="absolute -top-7 left-1/2 size-[326px] -translate-x-1/2 -translate-y-1/2 sm:hidden"
          />
          <img
            src={heroMedium}
            alt="Hero Medium"
            className="absolute -top-10 left-1/2 hidden size-[490px] -translate-x-1/2 -translate-y-1/2 sm:block xl:hidden"
          />
        </div>
        <img
          src={heroLarge}
          alt="Hero Large"
          className="absolute left-1/2 top-0 z-20 hidden size-[720px] -translate-x-1/2 xl:block"
        />
      </div>
      <img
        src={bottomRectangle}
        alt="Bottom Rectangle"
        className="absolute bottom-0 left-0 w-full sm:-mt-10"
      />
    </section>
  );
};
