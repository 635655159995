import { Link } from '@remix-run/react';
import { Button } from '@web/components/ui/button';
import {
  streamerSignInRoute,
  contactRoute,
  appRoute
} from '@web/utilities/routes';

import DiscordIcon from './discord_icon.svg';

export const JoinButtons = ({
  className = '',
  variant = 'discord',
  isAuthenticated = false
}: {
  className?: string;
  variant?: 'discord' | 'contact';
  isAuthenticated?: boolean;
}) => (
  <div
    className={`z-10 flex w-full flex-col items-center justify-center gap-6 md:flex-row md:gap-8 ${className}`}
  >
    {!isAuthenticated && (
      <Button
        variant="landing"
        className="h-12 w-full rounded-lg md:w-[199px]"
        asChild
      >
        <Link to={streamerSignInRoute()} prefetch="intent" title="Join Now">
          Join Now
        </Link>
      </Button>
    )}
    {isAuthenticated && (
      <Button
        variant="landing"
        className="h-12 w-full rounded-lg md:w-[199px]"
        asChild
      >
        <Link to={appRoute()} prefetch="intent" title="Go to app">
          Go to app
        </Link>
      </Button>
    )}
    {variant === 'discord' && (
      <Button
        variant="transparentOutline"
        className="h-12 w-full rounded-lg border-[1.5px] border-violet-500 bg-white text-violet-500 hover:bg-violet-100 hover:text-violet-500 md:w-[199px]"
        asChild
      >
        <Link
          to="https://discord.gg/eRHuwwzSzz"
          target="_blank"
          rel="noreferrer"
          title="Join our Discord Community!"
          className="flex items-center justify-center gap-2"
        >
          <img
            src={DiscordIcon}
            alt="discord_icon"
            className="fill-current text-violet-500"
          />
          Join our Discord
        </Link>
      </Button>
    )}
    {variant === 'contact' && (
      <Button
        variant="transparentOutline"
        className="h-12 w-full rounded-lg border border-violet-500 bg-white text-violet-500 md:w-[199px]"
        asChild
      >
        <Link to={contactRoute()} prefetch="intent" title="Get in touch">
          Contact for Brands
        </Link>
      </Button>
    )}
  </div>
);
